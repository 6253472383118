import React from 'react';

import SEO from '../components/seo';

import Header from '../components/header/Header';
// import Container from '../hoc/container/Container';
import InfoSection from '../components/infoSection/InfoSection';
import links from '../resources/links';

// import style from './about.module.css';

const AboutPage = () => {
	return (
		<>
			<SEO title="About Us - Superior Yachting" />
			<Header dataKey={links.about.link} />

			<InfoSection
				b
				heading="ANY YACHT"
				copy={
					<>
						Superior yachting specialise in providing charters on luxury yachts from all over the world. Our industry
						expertise can match you to your dream experience, be it Sail, Motor, Classic or Modern. As leading
						brokers, we're confident that we can deliver you the most professional chartering experience.
					</>
				}
				image="https://cdn.sanity.io/images/o5bo5dba/production/b01366149ab375b75897f11bb4105278b6cbb87a-1920x1224.jpg?w=2000&h=2000&fit=max"
			/>

			<InfoSection
				heading="ANY DESTINATION"
				copy={
					<>
						We have in-depth knowledge of the top destinations you may consider dropping anchor and diving in. We
						deliver stress-free experiences, from the initial enquiry to the last step off the passerelle. Our team of
						industry experts will give you all the information you need to make an informed decision. We'll help you
						to choose the best destination for your needs.
					</>
				}
				image="https://cdn.sanity.io/images/o5bo5dba/production/2b91671a0ce82fa0b7e8c0ef2becf752bdc2f44f-1600x1067.jpg?w=2000&h=2000&fit=max"
			/>

			<InfoSection
				b
				heading="ANY EXPERIENCE"
				copy={
					<>
						Superior Yachting is well connected in the yachting industry. We're proud to be able to offer you all the
						available charter yachts in the world! Our partnerships make this possible. Ready to explore your options?
						We look forward to hearing from you.
					</>
				}
				image="https://cdn.sanity.io/images/o5bo5dba/production/0892f469f7bd4724e96a8202d46546b8c90d8d6b-1920x1280.jpg?w=2000&h=2000&fit=max"
			/>
			{/* TODO: Link to FAQ */}
		</>
	);
};

export default AboutPage;
